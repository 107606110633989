<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title"><img src="/assets/images/icons/resetSenha.png" class="mr-2" style="width: 30px"> Reset de Senha</h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="usuarioForm" novalidate>     
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    <img src="/assets/images/icons/information.png" class="mr-2" style="width: 30px">
                </div>
                <div class="col-sm-11 col-xs-11">
                    Insira seu email cadastrado no sistema. Você receberá um link do email para efetuar a troca da senha.
                </div>
            </div>   
            <div class="row">
                <div class="col-sm-12 col-xs-12">
                    &nbsp;
                </div>
            </div> 
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-4 col-xs-4">
                    
                    Email Usuário:
                </div>
                <div class="col-sm-7 col-xs-7" >
                    <cft-input errorMessage="Formato de email inexistente " label="Email usuário">
                        <input name="US_USEMAIL" class="form-control" formControlName="US_USEMAIL" autocomplete="off" placeholder="Email Usuário">
                    </cft-input>
                </div>
                <div class="col-xs-12 col-sm-12" >
                    <span class="help-block pull-right has-error-block" *ngIf="usuarioForm.get('US_USEMAIL').hasError('emailInvalido')" style="color:red"><i class="fa fa-remove"></i> Email não cadastrado </span>
                  </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="EnviarEmail(usuarioForm.value)"type="button" class="btn btn-primary " [disabled]="!usuarioForm.valid" >
            <img src="/assets/images/icons/email2.png" class="mr-2" style="width: 30px"> Enviar
        </button> 
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose() ">Cancelar</button>
        
    </div>
</div>


