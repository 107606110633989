<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div> 

  <div class="row">
    <div class="col-sm-3 col-xs-3">&nbsp;</div>
      <div class="col-sm-6 col-xs-6 badge badge-primary"> <h5><img src="/assets/images/icons/jogo1.png" class="mr-2" style="width: 30px"> Cadastro de Jogos </h5></div>
  </div>
  
  
  <div class="row">
    <div class="col-sm-3 col-xs-3">
      &nbsp;
    </div>
  
    <div class="col-sm-6 col-xs-6 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
        <form>
            <div class="row">
                <div class="form-group form-inline">
                    Filtro: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="service.searchTerm"/>
                    <span class="ml-3" *ngIf="service.loading$ | async">Carregando...</span>
                </div>
                <div class="col-sm-2 col-xs-2"></div>
                <div class="col-sm-4 col-xs-4">
                <a class="nav-link" [routerLink]="['/jogo']">
                    <span tooltip="Adicionar Jogo" placement="top" show-delay="500">
                    <img src="/assets/images/icons/add.png" class="mr-2" style="width: 30px"> Jogo
                    </span> 
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-xs-12 table-responsive-xl">
                <table class="table table-striped col-sm-12 col-xs-12">
                    <thead>
                    <tr>
                        <th scope="col" sortable="OBJ_COMPETICAO.CP_CPDESCRICAO" (sort)="onSort($event)">Competição</th>
                        <th scope="col" sortable="OBJ_CLUBE1.CL_CLSIGLA" (sort)="onSort($event)">Clube</th>
                        <th scope="col" >X</th>
                        <th scope="col" sortable="OBJ_CLUBE2.CL_CLSIGLA" (sort)="onSort($event)">Clube</th>
                        <th scope="col">Ação</th>
                        <th scope="col">Ação</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let jogo of jogos$ | async">
                        <td>
                            <img [src]="jogo.OBJ_COMPETICAO.CP_CPFOTO" class="mr-2" style="width: 30px">
                            <span [tooltip]="jogo.OBJ_COMPETICAO.CP_CPDESCRICAO" placement="top" show-delay="500">
                                <ngb-highlight [result]="jogo.OBJ_COMPETICAO.CP_CPDESCRICAO.substring(0,15)" [term]="service.searchTerm"></ngb-highlight>
                            </span>
                        </td>
                        
                        <td>
                            <img [src]="jogo.OBJ_CLUBE1.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                            <span [tooltip]="jogo.OBJ_CLUBE1.CL_CLNOME" placement="top" show-delay="500">
                            <ngb-highlight [result]="jogo.OBJ_CLUBE1.CL_CLSIGLA" [term]="service.searchTerm"></ngb-highlight>
                            </span>
                        </td>
                        <td>X</td>
                        <td>
                            <img [src]="jogo.OBJ_CLUBE2.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                            <span [tooltip]="jogo.OBJ_CLUBE2.CL_CLNOME" placement="top" show-delay="500">
                            <ngb-highlight [result]="jogo.OBJ_CLUBE2.CL_CLSIGLA" [term]="service.searchTerm"></ngb-highlight>
                            </span>
                        </td>
                        <td>
                            <a class="nav-link" [routerLink]="['/jogo',jogo.JG_JGID]">
                            <span tooltip="Editar" placement="top" show-delay="500">
                                <i class="fa fa-edit"></i>
                            </span> 
                            </a>
                        </td>
                        <td><a class="nav-link exemplo_4"  (click)="ExcluiJogo(jogo.JG_JGID);"> 
                            <span tooltip="Excluir" placement="top" show-delay="500">
                              <img src="/assets/images/icons/excluir.png" class="mr-2" style="width: 10px" >
                            </span> 
                            </a>
                          </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>

                <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [ngValue]="2">2 itens por página</option>
                <option [ngValue]="4">4 itens por página</option>
                <option [ngValue]="6">6 itens por página</option>
                </select>
            </div>  
        </form>
    </div>
  </div>
