<cft-header *ngIf="isLoggedIn$ | async "></cft-header>
        
        <router-outlet>
        </router-outlet>
<footer *ngIf="isLoggedIn$ | async " class="py-5 bg-dark">
    <div class="container">
        <div class="pull-right hidden-xs">
        <b>Version</b> 1.0.0
        </div>
        <strong>Chutes FutMesa</strong> Eizu Soluções
    </div>
</footer>

<!-- <footer class="py-5 bg-dark" *ngIf="isLoggedIn$ | async " class="main-footer">
    <div class="container">
      <p class="m-0 text-center text-white"><strong>Chutes FutMesa</strong> Eizu Soluções
    </div>
</footer> --><ngx-scrolltop></ngx-scrolltop>