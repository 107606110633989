<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">&nbsp;</div>
    <div class="col-sm-8 col-xs-8 badge badge-primary"> <h5><img src="/assets/images/icons/jogador.png" class="mr-2" style="width: 30px"> Cadastro de Jogadores</h5></div>
</div>



<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div> 
    
    <div class="col-sm-8 col-xs-8 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
        <h4>Dados do Jogador</h4>
        <form [formGroup]="jogadorForm" novalidate>  
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    &nbsp;
                </div>
                <div class="col-sm-5  col-xs-5">
                    <img [src]= "pathimagecomplete"  class="mr-2" style="width: 50px">
                </div>
            </div>    
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>    
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div> 
                <div class="col-sm-3 col-xs-3">
                        Nome do Jogador:
                </div>
                <div class="col-sm-5  col-xs-5">
                    <cft-input errorMessage="Campo obrigatório e com 6 carateres" label="login do usuário">
                        <input name="JO_JONOME" class="form-control" formControlName="JO_JONOME" autocomplete="off" placeholder="Nome completo">
                    </cft-input>
                </div>
                <div class="col-xs-12 col-sm-2" >
                    <!-- <span class="help-block pull-right has-error-block" *ngIf="usuarioForm.get('US_USLOGIN').hasError('loginInvalido') === 'VALID'" style="color:red"><i class="fa fa-remove"></i> Login já existente</span> -->
                    <span class="help-block pull-right has-error-block" *ngIf="jogadorForm.get('JO_JONOME').hasError('jogadorInvalido')" style="color:red"><i class="fa fa-remove"></i> Nome jogador já existente</span>
                </div> 
            </div>
            
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div> 
                <div class="col-sm-3 col-xs-3">
                        Apelido:
                </div>
                <div class="col-sm-5  col-xs-5">
                    <cft-input errorMessage="Campo obrigatório e com 6 carateres" label="login do usuário">
                        <input name="JO_JOAPELIDO" class="form-control" formControlName="JO_JOAPELIDO" autocomplete="off" placeholder="Apelido">
                    </cft-input>
                </div>
                <div class="col-xs-12 col-sm-2" >
                    <!-- <span class="help-block pull-right has-error-block" *ngIf="usuarioForm.get('US_USLOGIN').hasError('loginInvalido') === 'VALID'" style="color:red"><i class="fa fa-remove"></i> Login já existente</span> -->
                    <span class="help-block pull-right has-error-block" *ngIf="jogadorForm.get('JO_JOAPELIDO').hasError('apelidoInvalido')" style="color:red"><i class="fa fa-remove"></i> Apelido já existente</span>
                </div> 
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div> 
                <div class="col-sm-3 col-xs-3">
                        Nº Matricula:
                </div>
                <div class="col-sm-5  col-xs-5">
                    <cft-input errorMessage="Matricula é um campo numérico e obrigatório" label="nº matricula">
                        <input name="JO_JOMATRICULA" class="form-control" formControlName="JO_JOMATRICULA" autocomplete="off" placeholder="Nº Matricula">
                    </cft-input>
                </div>
                <div class="col-xs-12 col-sm-2" >
                    <!-- <span class="help-block pull-right has-error-block" *ngIf="usuarioForm.get('US_USLOGIN').hasError('loginInvalido') === 'VALID'" style="color:red"><i class="fa fa-remove"></i> Login já existente</span> -->
                    <span class="help-block pull-right has-error-block" *ngIf="jogadorForm.get('JO_JOMATRICULA').hasError('matriculaInvalida')" style="color:red"><i class="fa fa-remove"></i> Matricula já cadastrada</span>
                </div> 
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    Foto:
                </div>
                <div class="col-sm-4 col-xs-4" >
                    <cft-input errorMessage="Campo obrigatório e com 2 carateres" label="Sigla do Clube">
                        <input type="file" name="JO_JOFOTO" class="form-control" formControlName="JO_JOFOTO" autocomplete="off" (change)="handleFileInput($event.target.files)">
                    </cft-input>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    Clube:
                </div>
                <div class="col-sm-5  col-xs-5" >
                    <select name="JO_CLID" class="form-control" formControlName="JO_CLID">
                        <option *ngFor=  "let c of (clubes)" [value]="c.CL_CLID">{{c.CL_CLNOME}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    Ativo:
                </div>
                <div class="col-sm-5 col-xs-5" style="text-align: left;">
                    <input type="checkbox" name="JO_JOATIVO" class="form-control" formControlName="JO_JOATIVO" autocomplete="off" placeholder="Ativo">
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-sm-2 col-xs-2">
                &nbsp;
            </div>
            <div class="col-sm-8 col-xs-8">
                <button (click)="SalvarJogador(jogadorForm.value)"type="button" class="btn btn-primary pull-right btn-block" [disabled]="!jogadorForm.valid" >
                    <i class="fa fa-save"></i> Salvar
                </button>
            </div>
            <div class="col-sm-3 col-xs-3" >
                &nbsp;
            </div>
        </div>
    </div>
</div>

  