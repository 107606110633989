<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
 
<div class="row">
    <div class="col-sm-2 col-xs-2">&nbsp;</div>
    <div class="col-sm-8 col-xs-8 badge badge-primary"> <h5><img src="/assets/images/icons/ptscompeticaojogador.png" class="mr-2" style="width: 30px"> Cadastro de Pontuação do Jogador por Competição </h5></div>
</div>

<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
    <div class="col-sm-8 col-xs-8 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
        <h4>Dados da Pontuação de Competições</h4>
        <form [formGroup]="ptscompeticaojogadorForm" novalidate>  
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Ano:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <select name="ano" class="form-control" formControlName="ano" #ano (change) ="FiltraCompeticao(ano.value)" [attr.disabled]="this.ptscompeticaojogador.PJ_PJID">
                        
                        <option *ngFor=  "let c of (anos)" [value]="c.ANO">{{c.ANO}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
            </div> 
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                        Competição: 
                </div>
                <div class="col-sm-7 col-xs-7" >
                    <cft-input errorMessage="Competição deve ser preenchida" label="Competição">
                        <select #PJ_CPID name="PJ_CPID" class="form-control" formControlName="PJ_CPID" (click)="MostraRodada(PJ_CPID.value)" [attr.disabled]="this.ptscompeticaojogador.PJ_PJID">
                            <option  *ngIf="ptscompeticaojogador.PJ_PJID == null" value="">--</option>
                            <option *ngFor=  "let c of (competicoes)" [value]="c.CP_CPID">{{c.CP_CPDESCRICAO}} - {{c.OBJ_Rodada.RO_RODESCRICAO}} </option>
                        </select>
                    </cft-input> 
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    &nbsp;
                </div>
                <div *ngIf="show" >
                    <ngb-toast *ngIf="show" header="{{competicaoId.CP_CPDESCRICAO}}" [delay]="5000"
                        [autohide]="autohide" (mouseenter)="autohide = false" (mouseleave)="autohide = true"
                        (hide)="show = false; autohide = true" [class.bg-warning]="!autohide" >
                        <div class="row">
                            <div class="col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <img [src]="competicaoId.CP_CPFOTO" class="mr-2" style="width: 30px">
                                    <!-- </div>
                                    <div class="col-sm-11 col-xs-11"> -->
                                        {{ competicaoId.CP_CPDESCRICAO }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        {{ competicaoId.OBJ_Rodada.RO_RODESCRICAO }} Inicio:{{ competicaoId.CP_CPDATAINICIO  | date: 'dd/MM/yyyy' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngb-toast>
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;

                </div>
                <div class="col-sm-2 col-xs-2">
                        Jogo: 
                </div>
                <div class="col-sm-7 col-xs-7" >
                    <cft-input errorMessage="Jogo deve ser preenchido" label="Jogo">
                        <select #PJ_JGID name="PJ_JGID" class="form-control" formControlName="PJ_JGID" (change) = "EscolhaJogo(PJ_JGID.value)" [attr.disabled]="this.ptscompeticaojogador.PJ_PJID">
                            <option  *ngIf="ptscompeticaojogador.PJ_PJID == null" value="">--</option>
                            <option *ngFor=  "let c of (jogos)" [value]="c.JG_JGID">{{c.OBJ_CLUBE1.CL_CLSIGLA}} X {{c.OBJ_CLUBE2.CL_CLSIGLA}} </option>
                        </select>
                    </cft-input> 
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    &nbsp;
                </div>
                <div *ngIf="showJogo" >
                    <ngb-toast *ngIf="showJogo" header="{{jogoId.OBJ_CLUBE1.CL_CLSIGLA}} X {{jogoId.OBJ_CLUBE2.CL_CLSIGLA}}" [delay]="10000"
                        [autohide]="autohideJogo" (mouseenter)="autohideJogo = false" (mouseleave)="autohideJogo = true"
                        (hide)="showJogo = false; autohideJogo = true" [class.bg-warning]="!autohideJogo" >
                        <div class="row">
                            <div class="col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <img [src]="jogoId.OBJ_CLUBE1.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                        {{jogoId.OBJ_CLUBE1.CL_CLSIGLA}} X
                                        <img [src]="jogoId.OBJ_CLUBE2.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                        {{jogoId.OBJ_CLUBE2.CL_CLSIGLA}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngb-toast>
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>   
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Jogador:
                </div>
                <div class="col-sm-7 col-xs-7" >
                    <cft-input errorMessage="Jogador deve ser preenchido" label="Jogador">
                        <select #PJ_JOID name="PJ_JOID" class="form-control" formControlName="PJ_JOID" (click)="MostraJogador(PJ_JOID.value)" [attr.disabled]="this.ptscompeticaojogador.PJ_PJID">
                            <option *ngFor=  "let j of (jogadores)" [value]="j.JO_JOID">{{j.JO_JOAPELIDO}}</option>
                        </select>
                    </cft-input> 
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    &nbsp;
                </div>
                <div *ngIf="showJogadores == true && jogadorId != null" >
                    <ngb-toast *ngIf="showJogadores" header="{{jogadorId.JO_JONOME}}" [delay]="5000"
                        [autohide]="autohideJog" (mouseenter)="autohideJog = false" (mouseleave)="autohideJog = true"
                        (hide)="showJogadores = false; autohideJog = true" [class.bg-warning]="!autohideJog" >
                        <div class="row">
                            <div class="col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <img [src]="jogadorId.JO_JOFOTO" class="mr-2" style="width: 30px">
                                        {{ jogadorId.JO_JONOME }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <img [src]="jogadorId.OBJ_CLUBE.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                        {{ jogadorId.OBJ_CLUBE.CL_CLNOME }}  Ranking: ...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngb-toast>
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Pontos:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJPONTOS" class="form-control" formControlName="PJ_PJPONTOS" autocomplete="off" placeholder="Pontos">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Colocação:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJCOLOCACAO" class="form-control" formControlName="PJ_PJCOLOCACAO" autocomplete="off" placeholder="Colocação">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Pontos Ganhos:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJPONTOSGANHOS" class="form-control" formControlName="PJ_PJPONTOSGANHOS" autocomplete="off" placeholder="Pontos Ganhos">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Jogos:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJJOGOS" class="form-control" formControlName="PJ_PJJOGOS" autocomplete="off" placeholder="Jogos">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Vitórias:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJVITORIAS" class="form-control" formControlName="PJ_PJVITORIAS" autocomplete="off" placeholder="Vitórias">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Empates:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJEMPATE" class="form-control" formControlName="PJ_PJEMPATE" autocomplete="off" placeholder="Empates">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Derrotas:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJDERROTA" class="form-control" formControlName="PJ_PJDERROTA" autocomplete="off" placeholder="Derrotas">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Gols-Pro:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJGOLSPRO" class="form-control" formControlName="PJ_PJGOLSPRO" autocomplete="off" placeholder="Gols-Pro">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Gols-Contra:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é um campo numérico e obrigatório " label="Pontos">
                        <input name="PJ_PJGOLCONTRA" class="form-control" formControlName="PJ_PJGOLCONTRA" autocomplete="off" placeholder="Gols-Contra">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Observação:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <cft-input errorMessage="Pontos é Campo obrigatório " label="Observação">
                        <input name="PJ_PJOBSERVACAO" class="form-control" formControlName="PJ_PJOBSERVACAO" autocomplete="off" placeholder="Observação">
                    </cft-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Ativo:
                </div>
                <div class="row col-sm-7 col-xs-7">
                    <div class="col-sm-2 col-xs-2" style="text-align: left;">
                        <input type="checkbox" name="PJ_PJATIVO" class="form-control" formControlName="PJ_PJATIVO" autocomplete="off" placeholder="Ativo">
                    </div>
                    <div class="col-sm-10 col-xs-10"></div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-sm-1 col-xs-1">
                &nbsp;
            </div>
            <div class="col-sm-9 col-xs-9">
                <button (click)="SalvarCompeticao(ptscompeticaojogadorForm.value)"type="button" class="btn btn-primary pull-right btn-block" [disabled]="!ptscompeticaojogadorForm.valid" >
                    <i class="fa fa-save"></i> Salvar
                </button>
                
            </div>
            <div class="col-sm-3 col-xs-3" >
                &nbsp;
            </div>
        </div>
    </div>
</div>
