<div *ngIf="blnLogado == false ">
  <nav  class="navbar navbar-expand-md bg-dark navbar-dark fixed-top">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto"></ul>
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a [routerLink]="['/login']" class="fa fa-user"> Login </a>
        </li>
    </ul>
    </div>
  </nav>
</div>


<section class="content">
  <div  class="jumbotron welcome-jumbotron" style="height: 600px;">

    <div class="row">
      <div class="col-sm-4 col-xs-4">&nbsp;</div>
      <div class="col-sm-7 col-xs-7">
        <!-- <img src="/assets/images/icons/jogo1.png" class="mr-2" style="width: 20px; color: white;"> -->
        <img src="/assets/images/fotosCompeticoes/torneiodasinho2022.jpg" class="mr-2" style="height: 400px; color: white;">
      </div>
    </div>
    
      <!-- <h1>Bem vindo ao Chutes FutMesa!</h1>
      <h1>O site de inscrição da Federação Paulista de Futebol de Mesa!</h1> -->
      <!-- <p *ngIf="blnLogado == false" >Quer jogar com a gente? Cadastre- se agora !! </p>
      <p *ngIf="blnLogado == true" >Começe jogar agora !! </p>
      <a *ngIf="blnLogado == false" class="btn btn-primary btn-lg" [routerLink]="['/login']"> Entre Aqui</a>
      <a *ngIf="blnLogado == true" class="btn btn-primary btn-lg" [routerLink]="['/equipe']"> Jogar Aqui</a> -->
  </div>
</section>