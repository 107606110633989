<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<!-- <div class="container"> -->
    <div class="row jumbotron">
        <div class="col-sm-3 col-xs-3 " >
            <div class="row ">
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-10 col-xs-10 card mb-3" style="text-align: center;">

                    <div *ngIf = "mercadoFechado == 0">
                        <h5> Mercado Fecha em:</h5>
                        <h2 style="font-size: xx-large; color: green;"><strong>{{diasFechaMercado}} dia(s) </strong></h2>
                    </div>
                    <div *ngIf = "mercadoFechado != 0">
                        <h5> Mercado :</h5>
                        <h2 style="font-size: xx-large; color: red;"><strong>FECHADO </strong></h2>
                    </div>

                    <div *ngIf="EquipeUsuario != null" class="card h-100 d-flex align-items-center" style="text-align: center;">
                        <img [src]="EquipeUsuario.EQ_EQESCUDO" class="mr-2" style="width: 80px;">
                        <h5>{{ EquipeUsuario.EQ_EQNOME }}</h5>
                        <h4>{{ usuarioLogado.US_USNOMETRATAMENTO}} </h4>
                    </div>
                    <div *ngIf="usuarioLogado != null">
                        <a routerLinkActive="active" class="nav-link" [routerLink]="['/equipe',usuarioLogado.US_USID]">
                            <div class="rounded-lg perfil" >
                                <img src="/assets/images/icons/usercolor.png" class="mr-2" style="width: 50px; color: white; ">
                                <span class="substituirTexto">Perfil</span>
                            </div>
                        </a>
                    </div>
                    <!-- <button class="btn btn-primary pull-right btn-block"></button> -->
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-5 col-xs-5 card ">
                    <div class="card-body" style="text-align: center;">
                        <h4>Posição:</h4>
                        <div *ngIf="rankingEquipe != null" class="colocacao">
                            {{rankingEquipe.COLOCACAO}}º
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 col-xs-5 card">
                    <div class="card-body"  style="text-align: center;">
                        <div *ngIf="rankingEquipe != null" class="colocacao">
                            <h4>Pts Total:</h4> {{rankingEquipe.TOTAL}}
                        </div>

                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-10 col-xs-10 card ">
                    <div class="card-body">
                        <button class="btn btn-primary pull-right btn-block" data-toggle="modal" data-target="#PtsUltimaRodada" type="button">
                            <img src="assets/images/icons/rating.png" class="mr-2" style="width: 30px; color: white; ">
                            Pts Última Rodada
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-10 col-xs-10 card ">
                    <div class="card-body">
                        <button class="btn btn-primary pull-right btn-block" data-toggle="modal" data-target="#PtsGeral" type="button">
                            <img src="assets/images/icons/ptscompeticaojogador2.png" class="mr-2" style="width: 30px; color: white; ">
                            Pontuação Geral
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-10 col-xs-10 card ">
                    <div class="card-body">
                        <button class="btn btn-primary pull-right btn-block" data-toggle="modal" data-target="#idRanking" type="button">
                            <img src="assets/images/logos clubes/fpfm.png" class="mr-2" style="width: 50px; color: white; ">
                            Ranking Fpfm
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-9 col-xs-9 " >
            <div class="row">
                <div class="col-sm-11 col-xs-11  card mb-5">
                    <div class="card-body">Basic card</div>
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-5 col-xs-5 card mb-5">
                    <div class="card-header"><h5>Escalação</h5></div>
                    <div class="card-body">
                        <div *ngIf = "mercadoFechado == 0 && EquipeUsuario != null && osSeusEscalados != null" class="rounded-lg substituir" >
                            <a routerLinkActive="active" class="nav-link" [routerLink]="['/equipejogador']">
                                <img src="/assets/images/icons/replace.png" class="mr-2" style="width: 50px; color: white; ">
                                <span class="substituirTexto">Substituir Jogadores</span>
                            </a>
                        </div>
                        <div *ngIf = "mercadoFechado != 0 && EquipeUsuario != null && osSeusEscalados != null" class="rounded-lg substituir" >
                            <a routerLinkActive="active" class="nav-link" [routerLink]="['/equipejogador']">
                                <img src="/assets/images/icons/teams.png" class="mr-2" style="width: 50px; color: white; ">
                                <span class="substituirTexto">Ver time</span>
                            </a>
                        </div>
                        <div *ngIf = "osSeusEscalados == null" class="rounded-lg substituir" >
                            <a routerLinkActive="active" class="nav-link" [routerLink]="['/equipejogador']">
                                <img src="/assets/images/icons/teams.png" class="mr-2" style="width: 50px; color: white; ">
                                <span class="substituirTexto">Escalar time</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>

                <div class="col-sm-5 col-xs-5 card mb-5">
                    <div class="card-header">
                        <h5> Competições </h5>
                    </div>
                    <div class="card-body">
                        <button class="btn btn-primary pull-right btn-block" data-toggle="modal" data-target="#idCompeticoes" type="button">
                            <img src="/assets/images/icons/competicao.png" class="mr-2" style="width: 50px; color: white; ">
                            Competições
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-11 col-xs-11 mb-1 " >
                    Top Escalados
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-xs-3 card mb-5 rounded p-1 ">
                    <div class="row">
                        <div class="col-sm-4 col-xs-4" >
                            <img src="{{maisEscaladosFoto[0]}}" style="width:90px">
                        </div>
                        <div class="col-sm-8 col-xs-8 " style="text-align: center;">
                            <h2><strong>1º</strong></h2>
                            <h4><strong>{{maisEscaladosJogador[0].substring(0,15)}} </strong></h4>
                            <div class="row"><img src="{{maisEscaladosEmblema[0]}}" style="width:40px"></div>
                            <div class="row"> <h5>{{maisEscaladodClube[0]}}</h5></div>
                            <div class="row"> <h5>{{maisEscaladosTotal[0]}} pessoas escalaram</h5></div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>

                <div class="col-sm-3 col-xs-3 card mb-5 ">
                    <div class="row">
                        <div class="col-sm-4 col-xs-4" >
                            <img src="{{maisEscaladosFoto[1]}}" style="width:90px">
                        </div>
                        <div class="col-sm-8 col-xs-8 " style="text-align: center;">
                            <h2><strong>2º</strong></h2>
                            <h4><strong>{{maisEscaladosJogador[1].substring(0,15)}} </strong></h4>
                            <div class="row"><img src="{{maisEscaladosEmblema[1]}}" style="width:40px"></div>
                            <div class="row"> <h5>{{maisEscaladodClube[1]}}</h5></div>
                            <div class="row"> <h5>{{maisEscaladosTotal[1]}} pessoas escalaram</h5></div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>

                <div class="col-sm-3 col-xs-3 card mb-5">
                    <div class="row">
                        <div class="col-sm-4 col-xs-4" >
                            <img src="{{maisEscaladosFoto[2]}}" style="width:90px">
                        </div>
                        <div class="col-sm-8 col-xs-8 " style="text-align: center;">
                            <h2><strong>3º</strong></h2>
                            <h4><strong>{{maisEscaladosJogador[2].substring(0,15)}} </strong></h4>
                            <div class="row"><img src="{{maisEscaladosEmblema[2]}}" style="width:40px"></div>
                            <div class="row"> <h5>{{maisEscaladodClube[2]}}</h5></div>
                            <div class="row"> <h5>{{maisEscaladosTotal[2]}} pessoas escalaram</h5></div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-11 col-xs-11  card mb-5">
                    <mat-accordion><!--Escalados-->
                        <mat-expansion-panel (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false" [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                            <!-- Self aware panel -->
                            </mat-panel-title>
                            <mat-panel-description>
                                <div style="text-align: center;" class="mt-3">
                                    <h1><img src="/assets/images/icons/top.png" class="mr-2" style="width: 40px"><strong>Top Escalados</strong></h1>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="row">
                                <div class="col-sm-12 col-xs-12" >
                                    <div class="testeJogadores border border-primary rounded shadow-lg p-4 mb-4 bg-white">
                                        <div class="example-container col-sm-12 col-xs-12 ">
                                            <div class="col-sm-12 col-xs-12 header" id="myHeader">
                                                <div class="row">
                                                    <div class="col-sm-2 col-xs-2">
                                                        CLUBE
                                                    </div>
                                                    <div class="col-sm-4 col-xs-4">
                                                        JOGADORES
                                                    </div>

                                                    <div class="col-sm-3 col-xs-3"></div>
                                                    <div class="col-sm-2 col-xs-2">
                                                        Qtd. Escalação
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="example-container col-sm-12 col-xs-12 ">
                                            <div *ngFor="let item of osMaiEscalados" >
                                                <div class="row border rounded shadow-lg bg-white mb-3">
                                                    <div class="col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-sm-2 col-xs-2 mt-2">
                                                                <div class="row"></div><img [src]="item.AJ_CLEMBLEMA" class="mr-2" style="height: 50px">
                                                                <div class="row ml-4" >&nbsp;{{item.AJ_CLSIGLA}}</div>
                                                            </div>
                                                            <div class="col-sm-2 col-xs-2">
                                                                <img [src]="item.AJ_JOFOTO" class="mr-2" style="height:100px">
                                                            </div>
                                                            <div class="col-sm-3 col-xs-3 mt-5">
                                                                <strong>{{item.AJ_JOAPELIDO.toUpperCase()}}</strong>
                                                            </div>

                                                            <div class="col-sm-3 col-xs-3"></div>
                                                            <div class="col-sm-2 col-xs-2 mt-5">
                                                                <h4><strong> {{item.AJ_AJPONTOS}}</strong> </h4>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </mat-expansion-panel>
                    </mat-accordion><!--Escalados ****** FIM-->
                </div>
            </div>
            <div class="row">
                <div class="col-sm-11 col-xs-11 mb-1 " >
                    Seus Pontuadores
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-xs-3 card mb-5">
                    <div class="row">
                        <div class="col-sm-4 col-xs-4" >
                            <img src="{{seusEscaladosFoto[0]}}" style="width:90px">
                        </div>
                        <div class="col-sm-8 col-xs-8 " style="text-align: center;">
                            <h2><strong>{{seusEscaladosTotal[0]}} PTS</strong></h2>
                            <h4><strong>{{seusEscaladosJogador[0].substring(0,15)}} </strong></h4>
                            <div class="row"><img src="{{seusEscaladosEmblema[0]}}" style="width:40px"></div>
                            <div class="row"> <h5>{{seusEscaladodClube[0]}}</h5></div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-1 card mb-5">
                    <div class="row">
                        <div class="col-sm-4 col-xs-4" >
                            <img src="{{seusEscaladosFoto[1]}}" style="width:90px">
                        </div>
                        <div class="col-sm-8 col-xs-8 " style="text-align: center;">
                            <h2><strong>{{seusEscaladosTotal[1]}} PTS</strong></h2>
                            <h4><strong>{{seusEscaladosJogador[1].substring(0,15)}} </strong></h4>
                            <div class="row"><img src="{{seusEscaladosEmblema[1]}}" style="width:40px"></div>
                            <div class="row"> <h5>{{seusEscaladodClube[1]}}</h5></div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-xs-1 ">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-1 card mb-5">
                    <div class="row">
                        <div class="col-sm-4 col-xs-4" >
                            <img src="{{seusEscaladosFoto[2]}}" style="width:90px">
                        </div>
                        <div class="col-sm-8 col-xs-8 " style="text-align: center;">
                            <h2><strong>{{seusEscaladosTotal[2]}} PTS</strong></h2>
                            <h4><strong>{{seusEscaladosJogador[2].substring(0,15)}} </strong></h4>
                            <div class="row"><img src="{{seusEscaladosEmblema[2]}}" style="width:40px"></div>
                            <div class="row"> <h5>{{seusEscaladodClube[2]}}</h5></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-11 col-xs-11  card mb-5">
                    <mat-accordion><!--Seus Pontuadores-->
                        <mat-expansion-panel (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false" [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                            <!-- Self aware panel -->
                            </mat-panel-title>
                            <mat-panel-description>
                                <div style="text-align: center;" class="mt-3">
                                    <h1><img src="/assets/images/icons/pointers.png" class="mr-2" style="width: 40px"><strong>Seus Pontuadores</strong></h1>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="row">
                                <div class="col-sm-12 col-xs-12" >
                                    <div class="testeJogadores border border-primary rounded shadow-lg p-4 mb-4 bg-white">
                                        <div class="example-container col-sm-12 col-xs-12 ">
                                            <div class="col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-sm-2 col-xs-2">
                                                        CLUBE
                                                    </div>
                                                    <div class="col-sm-4 col-xs-4">
                                                        JOGADORES
                                                    </div>

                                                    <div class="col-sm-3 col-xs-3"></div>
                                                    <div class="col-sm-3 col-xs-3">
                                                        Pontos
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="example-container col-sm-12 col-xs-12 ">
                                            <div *ngFor="let item of osSeusEscalados" >
                                                <div class="row border rounded shadow-lg bg-white mb-3">
                                                    <div class="col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-sm-2 col-xs-2 mt-1">
                                                                <div class="row"><img [src]="item.AJ_CLEMBLEMA" class="mr-2" style="height:60px"></div>
                                                                <div class="row">&nbsp;&nbsp;&nbsp;{{item.AJ_CLSIGLA}}</div>
                                                            </div>
                                                            <div class="col-sm-2 col-xs-2">
                                                                <img [src]="item.AJ_JOFOTO" class="mr-2" style="height: 100px">
                                                            </div>
                                                            <div class="col-sm-3 col-xs-3 mt-5">
                                                                <strong>{{item.AJ_JOAPELIDO.toUpperCase()}}</strong>
                                                            </div>

                                                            <div class="col-sm-3 col-xs-3"></div>
                                                            <div class="col-sm-2 col-xs-2 mt-5">
                                                                <h4><strong> {{item.AJ_AJPONTOS}}</strong> Pontos</h4>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </mat-expansion-panel>
                    </mat-accordion><!--Seus Pontuadores ****** FIM-->
                </div>
            </div>
            <div class="row">
                <div class="col-sm-11 col-xs-11  card mb-5">
                    <mat-accordion><!--top  pontuadores-->
                        <mat-expansion-panel (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false" [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                            <!-- Self aware panel -->
                            </mat-panel-title>
                            <mat-panel-description>
                                <div style="text-align: center;" class="mt-3">
                                    <h1><img src="/assets/images/icons/top.png" class="mr-2" style="width: 40px"><strong>Top Pontuadores</strong></h1>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="row">
                                <div class="col-sm-12 col-xs-12" >
                                    <div class="testeJogadores border border-primary rounded shadow-lg p-4 mb-4 bg-white">
                                        <div class="example-container col-sm-12 col-xs-12 ">
                                            <div class="col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-sm-1 col-xs-1 mt-5" style="text-align: center;">
                                                        <strong>CL.</strong>
                                                    </div>
                                                    <div class="col-sm-2 col-xs-2 mt-5">
                                                        <strong>JOGADOR</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>PTS</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>PG</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>JG</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>V</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>E</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>D</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>GP</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>GC</strong>
                                                    </div>
                                                    <div class="col-sm-1 col-xs-1 mt-5">
                                                        <strong>SG</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="example-container col-sm-12 col-xs-12 ">
                                            <div *ngFor="let item of ptsCompeticao" >
                                                <div class="row border rounded shadow-lg bg-white mb-3">
                                                    <div class="col-sm-12 col-xs-12">
                                                        <div class="row" style="text-align: center;">
                                                            <div class="col-sm-1 col-xs-1 mt-2" style="text-align: center;">
                                                                <div class="row" ><img [src]="item.OBJ_JOGADOR.OBJ_CLUBE.CL_CLEMBLEMA" style="height:50px"></div>
                                                                <div class="row " style="text-align: center; ">&nbsp;&nbsp;&nbsp;{{item.OBJ_JOGADOR.OBJ_CLUBE.CL_CLSIGLA}}</div>
                                                            </div>
                                                            <div class="col-sm-2 col-xs-2" style="text-align: center;">
                                                                <div class="row" style="text-align: center;"><img [src]="item.OBJ_JOGADOR.JO_JOFOTO" class="mr-2" style="height: 60px"></div>
                                                                <div class="row" style="text-align: center;">{{item.OBJ_JOGADOR.JO_JOAPELIDO}}</div>
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJPONTOS}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJPONTOSGANHOS}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJJOGOS}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJVITORIAS}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJEMPATE}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJDERROTA}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJGOLSPRO}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJGOLCONTRA}}
                                                            </div>
                                                            <div class="col-sm-1 col-xs-1 mt-5">
                                                                {{item.PJ_PJSALDOGOLS}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </mat-expansion-panel>
                    </mat-accordion><!--top  pontuadores ****** FIM-->
                </div>
            </div>

        </div>
    </div>
<!-- </div> -->

<div class="modal" id="idCompeticoes">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Competições da Temporada</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body ">
            <div class="row">
                <div class="col-sm-12 col-xs-12 ">
                  <table class="table table-striped col-sm-12 col-xs-12">
                    <thead>
                    <tr>
                        <th scope="col" >Data Inicio</th>
                        <th scope="col" >Competicao</th>
                        <th scope="col" >Rodada</th>
                        <th scope="col" >Categoria Jogo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let competicao of (competicoes)">
                        <td>
                            {{competicao.CP_CPDATAINICIO  | date: 'dd/MM/yyyy'}}
                        </td>
                        <td>
                            <img [src]="competicao.CP_CPFOTO" class="mr-2" style="width: 30px">
                            <span [tooltip]="competicao.CP_CPDESCRICAO" placement="top" show-delay="500">
                            {{competicao.CP_CPDESCRICAO.substring(0,15)}}
                            </span>
                        </td>
                        <td>
                            {{competicao.OBJ_Rodada.RO_RODESCRICAO }}
                        </td>
                        <td>
                            {{competicao.OBJ_CATEGORIAJOGADOR.CJ_CJDESCRICAO}}
                        </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
</div>

<div class="modal" id="PtsGeral">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Competições da Temporada</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body ">
            <div class="row">
                <div class="col-sm-12 col-xs-12 ">
                  <table class="table table-striped col-sm-12 col-xs-12">
                    <thead>
                    <tr>
                        <th scope="col" >Colocação</th>
                        <th scope="col" >Equipe</th>
                        <th scope="col" >Usuário</th>
                        <th scope="col" >Pontos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let rkg of (rankingEquipeGeral)">
                        <td>
                            {{rkg.COLOCACAO}} º
                        </td>
                        <td>
                            <img [src]="rkg.AJ_EQESCUDO" class="mr-2" style="width: 30px">
                            {{rkg.AJ_EQNOME}}
                        </td>
                        <td>
                            {{rkg.AJ_USNOMETRATAMENTO}}
                        </td>
                        <td>
                            {{rkg.TOTAL}}
                        </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
</div>

<div class="modal" id="PtsUltimaRodada" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Colocação Ultima Evento</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body ">
            <div class="row">
                <div class="col-sm-12 col-xs-12 ">
                  <table class="table table-striped col-sm-12 col-xs-12">
                    <thead>
                    <tr>
                        <th scope="col" >Colocação</th>
                        <th scope="col" >Equipe</th>
                        <th scope="col" >Usuário</th>
                        <th scope="col" >Pontos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let rkg of (PontuacaoUltimaRodada)">
                        <td>
                            {{rkg.COLOCACAO}} º
                        </td>
                        <td>
                            <img [src]="rkg.AJ_EQESCUDO" class="mr-2" style="width: 30px">
                            {{rkg.AJ_EQNOME}}
                        </td>
                        <td>
                            {{rkg.AJ_USNOMETRATAMENTO}}
                        </td>
                        <td>
                            {{rkg.TOTAL}}
                        </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
</div>

<div class="modal" id="idRanking">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Ranking Federação {{dataranking | date: 'dd/MM/yyyy' }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->



        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Adulto</a>
              <ng-template ngbNavContent>
                <div class="modal-body ">
                    <div class="row">
                        <div class="col-sm-12 col-xs-12 ">
                          <table class="table table-striped col-sm-12 col-xs-12">
                            <thead>
                            <tr>
                                <th scope="col" >Pos</th>
                                <th scope="col" >Pos.Ano</th>
                                <th scope="col" >Matr.</th>
                                <th scope="col" >Jogador</th>
                                <th scope="col" >Clube</th>
                                <th scope="col" >Pontos</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let rk of (rankingGeralAdulto)">
                                <td>
                                    {{rk.RJ_RJPOSICAO}}°
                                </td>
                                <td>
                                    {{rk.RJ_RJPOSICAOANO}}°
                                </td>
                                <td>
                                    {{rk.RJ_JOMATRICULA}}
                                </td>
                                <td>
                                    <img [src]="rk.OBJ_JOGADOR.JO_JOFOTO" class="mr-2" style="width: 30px">
                                    {{rk.OBJ_JOGADOR.JO_JOAPELIDO}}
                                </td>
                                <td>
                                    <img [src]="rk.OBJ_JOGADOR.OBJ_CLUBE.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                    {{rk.OBJ_JOGADOR.OBJ_CLUBE.CL_CLSIGLA}}
                                </td>
                                <td>
                                    {{rk.RJ_RJPONTOS}}
                                </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Master</a>
              <ng-template ngbNavContent>
                <div class="modal-body ">
                    <div class="row">
                        <div class="col-sm-12 col-xs-12 ">
                          <table class="table table-striped col-sm-12 col-xs-12">
                            <thead>
                            <tr>
                                <th scope="col" >Pos</th>
                                <th scope="col" >Pos.Ano</th>
                                <th scope="col" >Matr.</th>
                                <th scope="col" >Jogador</th>
                                <th scope="col" >Clube</th>
                                <th scope="col" >Pontos</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let rk of (rankingGeralMaster)">
                                <td>
                                    {{rk.RJ_RJPOSICAO}}°
                                </td>
                                <td>
                                    {{rk.RJ_RJPOSICAOANO}}°
                                </td>
                                <td>
                                    {{rk.RJ_JOMATRICULA}}
                                </td>
                                <td>
                                    <img [src]="rk.OBJ_JOGADOR.JO_JOFOTO" class="mr-2" style="width: 30px">
                                    {{rk.OBJ_JOGADOR.JO_JOAPELIDO}}
                                </td>
                                <td>
                                    <img [src]="rk.OBJ_JOGADOR.OBJ_CLUBE.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                    {{rk.OBJ_JOGADOR.OBJ_CLUBE.CL_CLSIGLA}}
                                </td>
                                <td>
                                    {{rk.RJ_RJPONTOS}}
                                </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Sub 20</a>
              <ng-template ngbNavContent>
                <div class="modal-body ">
                    <div class="row">
                        <div class="col-sm-12 col-xs-12 ">
                          <table class="table table-striped col-sm-12 col-xs-12">
                            <thead>
                            <tr>
                                <th scope="col" >Pos</th>
                                <th scope="col" >Pos.Ano</th>
                                <th scope="col" >Matr.</th>
                                <th scope="col" >Jogador</th>
                                <th scope="col" >Clube</th>
                                <th scope="col" >Pontos</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let rk of (rankingGeralSub20)">
                                <td>
                                    {{rk.RJ_RJPOSICAO}}°
                                </td>
                                <td>
                                    {{rk.RJ_RJPOSICAOANO}}°
                                </td>
                                <td>
                                    {{rk.RJ_JOMATRICULA}}
                                </td>
                                <td>
                                    <img [src]="rk.OBJ_JOGADOR.JO_JOFOTO" class="mr-2" style="width: 30px">
                                    {{rk.OBJ_JOGADOR.JO_JOAPELIDO}}
                                </td>
                                <td>
                                    <img [src]="rk.OBJ_JOGADOR.OBJ_CLUBE.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                    {{rk.OBJ_JOGADOR.OBJ_CLUBE.CL_CLSIGLA}}
                                </td>
                                <td>
                                    {{rk.RJ_RJPONTOS}}
                                </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
              </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>

          <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
        </div>

      </div>
    </div>
</div>
<script>
    window.onscroll = function() {myFunction()};

    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    </script>
