<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div> 
   
  <div class="row">
    <div class="col-sm-1 col-xs-1">&nbsp;</div>
      <div class="col-sm-10 col-xs-10 badge badge-primary"> <h5> <img src="/assets/images/icons/ptscompeticaojogador.png" class="mr-2" style="width: 30px"> Cadastro de Pontuação do Jogador por Competição </h5></div>
  </div>
  
  <div class="row ">
    <div class="col-sm-1 col-xs-1">
      &nbsp;
    </div>
    <div class="col-sm-10 col-xs-10 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
        <form>
        <div class="row">
            <div class="form-group form-inline">
                Filtro: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="service.searchTerm"/>
                <span class="ml-3" *ngIf="service.loading$ | async">Carregando...</span>
            </div>
            <div class="col-sm-2 col-xs-2"></div>
            <div class="col-sm-4 col-xs-4">
            <a class="nav-link" [routerLink]="['/ptscompeticaojogador']">
                <span tooltip="Adicionar Pontuação do Jogador por Competição" placement="top" show-delay="500">
                <img src="/assets/images/icons/add.png" class="mr-2" style="width: 30px"> Pontuação do Jogador
                </span> 
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-xs-12 table-responsive-xl">
            <table class="table table-striped col-sm-12 col-xs-12">
                <thead>
                <tr>
                <th scope="col" sortable="OBJ_COMPETICAO.CP_CPDESCRICAO" (sort)="onSort($event)">Competicao</th>
                <th scope="col" sortable="OBJ_COMPETICAO.OBJ_Rodada.RO_RODESCRICAO" (sort)="onSort($event)">Rodada</th>
                <th scope="col" sortable="OBJ_JOGADOR.JO_JOAPELIDO" (sort)="onSort($event)">Jogador</th>
                <th scope="col" sortable="PJ_PJPONTOS" (sort)="onSort($event)">Pontos</th> 
                
                <th scope="col">Ação</th>
                <th scope="col">Ação</th>
                </tr> 
                </thead>
                <tbody>
                <tr *ngFor="let ptscompeticaojogador of ptscompeticoesjogadores$ | async">
                <td>
                    <!-- <img src="/assets/images/icons/ptscompeticaojogador.png" class="mr-2" style="width: 30px"> -->
                    <img [src]="ptscompeticaojogador.OBJ_COMPETICAO.CP_CPFOTO" class="mr-2" style="width: 30px">
                    <span [tooltip]="ptscompeticaojogador.OBJ_COMPETICAO.CP_CPDESCRICAO" placement="top" show-delay="500">
                    <ngb-highlight [result]="ptscompeticaojogador.OBJ_COMPETICAO.CP_CPDESCRICAO.substring(0,15)" [term]="service.searchTerm"></ngb-highlight>
                    </span>
                </td>
                <td>
                    <ngb-highlight [result]="ptscompeticaojogador.OBJ_COMPETICAO.OBJ_Rodada.RO_RODESCRICAO" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <img [src]="ptscompeticaojogador.OBJ_JOGADOR.JO_JOFOTO" class="mr-2" style="width: 30px">
                    <span [tooltip]="ptscompeticaojogador.OBJ_JOGADOR.JO_JONOME + '-' +  ptscompeticaojogador.OBJ_JOGADOR.OBJ_CLUBE.CL_CLNOME "  placement="top" show-delay="500">
                        <ngb-highlight [result]="ptscompeticaojogador.OBJ_JOGADOR.JO_JOAPELIDO" [term]="service.searchTerm"></ngb-highlight>
                    </span>
                </td>
                <td>
                    <ngb-highlight [result]="ptscompeticaojogador.PJ_PJPONTOS" [term]="service.searchTerm"></ngb-highlight>
                </td>
                
                <td><a class="nav-link" [routerLink]="['/ptscompeticaojogador',ptscompeticaojogador.PJ_PJID]">
                    <span tooltip="Editar" placement="top" show-delay="500">
                        <i class="fa fa-edit"></i>
                    </span> 
                    </a>
                </td>
                <td><a class="nav-link exemplo_4"  (click)="ExcluiCompeticao(ptscompeticaojogador.PJ_PJID);"> 
                    <span tooltip="Excluir" placement="top" show-delay="500">
                    <img src="/assets/images/icons/excluir.png" class="mr-2" style="width: 20px" >
                    </span> 
                    </a>
                </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination
            [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
            <option [ngValue]="2">2 itens por página</option>
            <option [ngValue]="4">4 itens por página</option>
            <option [ngValue]="6">6 itens por página</option>
            </select>
        </div>
        </form>
    </div>
  </div>
  <router-outlet></router-outlet>        