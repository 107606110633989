<div class="container">
           <!-- Page Features -->
  {{panelOpenState}}          
  <ngx-scrolltop [backgroundColor]="'#212121'" [symbolColor]="'#fafafa'" [size]="40" [mode]="'classic'"
                  position="right" theme="gray">
  </ngx-scrolltop>
  
      <!-- Jumbotron Header -->
      <header class="jumbotron my-4">
        <h1 class="display-3">Perfil Equipe</h1>
        <form [formGroup]="equipeForm" novalidate>     
          <mat-accordion > <!--Cabeçalho-->
              <mat-expansion-panel (opened)="panelOpenState = true"
                                  (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <!-- Self aware panel -->
                  </mat-panel-title>
                  <mat-panel-description >
                      <h5><img src="/assets/images/icons/Equipe.png" class="mr-2" style="width: 30px"> Equipe - Dados Gerais</h5> 
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-sm-2 col-xs-2">
                      &nbsp;
                  </div>
                  <div class="col-sm-2 col-xs-2">
                          Nome Equipe:
                  </div>
                  <div class="col-sm-7  col-xs-7">
                      <cft-input errorMessage="Campo obrigatório e com 5 carateres" label="Nome da equipe">
                          <input name="EQ_EQNOME" class="form-control" formControlName="EQ_EQNOME" autocomplete="off" placeholder="Equipe" (change)="nomeEquipe = $event.target.value">
                      </cft-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-2 col-xs-2">
                      &nbsp;
                  </div>
                  <div class="col-sm-2 col-xs-2">
                      &nbsp;
                  </div>
                  <div class="col-xs-12 col-sm-2" >
                    <span class="help-block pull-right has-error-block" *ngIf="equipeForm.get('EQ_EQNOME').hasError('nomeEquipeInvalido')" style="color:red"><i class="fa fa-remove"></i> Já existe equipe com este nome</span>
                  </div>
                </div> 
                <div class="row" *ngIf="usuarioLogado.US_GUID == 1">
                  <div class="col-sm-2 col-xs-2">
                      &nbsp;
                  </div>
                  <div class="col-sm-2 col-xs-2">
                      Ativo:
                  </div>
                  <div class="row col-sm-7 col-xs-7">
                      <div class="col-sm-2 col-xs-2" style="text-align: left;">
                          <input type="checkbox" name="EQ_EQATIVO" class="form-control" formControlName="EQ_EQATIVO" autocomplete="off" placeholder="Ativo">
                      </div>
                      <div class="col-sm-10 col-xs-10"></div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-2 col-xs-2">
                      &nbsp;
                  </div>
                  <div class="col-sm-2 col-xs-2">
                          Comentários:
                  </div>
                  <div class="col-sm-7  col-xs-7">
                      <cft-input errorMessage="Campo obrigatório e com 5 carateres" label="Nome da equipe">
                          <input name="EQ_EQOBSERVACAO" class="form-control" formControlName="EQ_EQOBSERVACAO" autocomplete="off" placeholder="Comentários">
                      </cft-input>
                  </div>
              </div>   
              </mat-expansion-panel>
          </mat-accordion> <!--Cabeçalho *********** FIM -->

          <div class="row">
              <div class="col-sm-2 col-xs-2">
                  &nbsp;
              </div>
          </div> 
          <mat-accordion ><!--ESCUDO-->
              <mat-expansion-panel (opened)="panelOpenState = true"
                                  (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <!-- Self aware panel -->
                  </mat-panel-title>
                  <mat-panel-description>
                      <h5><img src="/assets/images/icons/clube1.png" class="mr-2" style="width: 30px"> Equipe - Escudo </h5> 
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-sm-3 col-xs-3">
                      &nbsp;
                  </div>
                  <div class="col-sm-3 col-xs-3 ">
                      <div class="col-sm-12 col-xs-12 badge badge-info " ><h6>Escudos</h6></div>
                      <div class="teste border border-primary rounded shadow-lg p-4 mb-4 bg-white gallery" id="gallery">
                          <div class="">
                              <div
                              cdkDropList
                              #todoListEscudo="cdkDropList"
                              [cdkDropListData]="EscudosFiltro"
                              [cdkDropListConnectedTo]="[doneListEscudo]"
                              (cdkDropListDropped)="dropEscudo($event)">
                                  <div *ngFor="let item of EscudosFiltro" cdkDrag>
                                      <img [src]="item.IM_IMPATH" class="mr-2" style="width: 40px">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-1 col-xs-1">
                      <div class="row">
                          &nbsp;
                      </div>
                      <div class="row">
                          &nbsp;
                      </div>
                      <div>
                          <span class="blink" tooltip="Arraste da esquerda para a direita" placement="top" show-delay="500">
                              <img src="/assets/images/icons/setaDireita.png" class="mr-2" style="width: 40px">
                          </span>
                      </div>
                  </div>
                  <div class="col-sm-3 col-xs-3" >
                      <div class="row">
                          <div class="col-sm-12 col-xs-12 badge badge-info "  > <h6>Escolher escudo</h6></div>
                      </div>
                      <div class="row">
                          <div class="col-sm-12 col-xs-12 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
                              <div class="row">
                                  <div class="col-sm-3 col-xs-3"></div>
                                  <div class="col-sm-6 col-xs-6 ">
                                      <div class="example-containerEscudoEscolha">
                                          <div
                                              cdkDropList
                                              #doneListEscudo="cdkDropList"
                                              [cdkDropListData]="EscudosEscolhido"
                                              [cdkDropListConnectedTo]="[todoListEscudo]"
                                              class="example-listEscudo"
                                              (cdkDropListDropped)="dropEscudo($event)">
                                              <div class="example-boxEscudo" *ngFor="let item of EscudosEscolhido" cdkDrag >
                                                  <img [src]="item.IM_IMPATH" class="mr-2" style="width: 40px">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              </mat-expansion-panel>
          </mat-accordion><!--ESCUDO ****** FIM-->
          <div class="row">
              <div class="col-sm-2 col-xs-2">
                  &nbsp;
              </div>
          </div> 
          <div class="row">
            <div class="col-sm-12 col-xs-12">
                &nbsp;
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-xs-2">
                &nbsp;
            </div>
            <div class="col-sm-8 col-xs-8">
                <button (click)="SalvarEquipe(equipeForm.value)" type="button" class="btn btn-primary pull-right btn-block" [disabled]="HabilitarSalvar()" >
                    <i class="fa fa-save"></i> Salvar
                </button>
                
            </div>
            <div class="col-sm-3 col-xs-3" >
                &nbsp;
            </div> 
          </div>
        </form>
      </header>
    </div>
    <!-- /.container -->
  
