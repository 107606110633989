<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">&nbsp;</div>
    <div class="col-sm-8 col-xs-8 badge badge-primary"> <h5><img src="/assets/images/icons/user1.png" class="mr-2" style="width: 30px"> Troca de Senha</h5></div>
</div>



<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div> 
    
    <div class="col-sm-8 col-xs-8 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
        <h4>Trocar Senha</h4>
        <form [formGroup]="usuarioForm" novalidate>        
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    Senha:
                </div>
                <div class="col-sm-5  col-xs-5" >
                    <cft-input errorMessage="Campo obrigatório e com 6 carateres" label="Senha do usuário">
                        <input type="password" name="US_USSENHA" class="form-control" formControlName="US_USSENHA" autocomplete="off" placeholder="Senha">
                    </cft-input>
                </div>
                <div class="col-xs-12 col-sm-2" >
                    <span class="help-block pull-right has-error-block" *ngIf="usuarioForm.hasError('senhaNoMatch')" style="color:red"><i class="fa fa-remove"></i> Senhas não conferem</span>
                    </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    Confirma Senha:
                </div>
                <div class="col-sm-5  col-xs-5" >
                    <cft-input errorMessage="Campo obrigatório e com 6 carateres" label="Senha do usuário">
                        <input type="password" name="US_USSENHA_CONFIRMA" class="form-control" formControlName="US_USSENHA_CONFIRMA" autocomplete="off" placeholder="Confirma Senha">
                    </cft-input>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-sm-2 col-xs-2">
                &nbsp;
            </div>
            <div class="col-sm-8 col-xs-8">
                <button (click)="TrocarSenha(usuarioForm.value)"type="button" class="btn btn-primary pull-right btn-block" [disabled]="!usuarioForm.valid" >
                    <i class="fa fa-save"></i> Salvar
                </button>
            </div>
            <div class="col-sm-3 col-xs-3" >
                &nbsp;
            </div>
        </div>
    </div>
</div>

  