<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
  </div> 

  <div class="row">
    <div class="col-sm-3 col-xs-3">&nbsp;</div>
      <div class="col-sm-6 col-xs-6 badge badge-primary"> <h5><img src="/assets/images/icons/jogador.png" class="mr-2" style="width: 30px"> Cadastro de Jogadores </h5></div>
  </div>
  
  
  <div class="row">
    <div class="col-sm-3 col-xs-3">
      &nbsp;
    </div>
  
    <div class="col-sm-6 col-xs-6 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
        <form>
            <div class="row">
                <div class="form-group form-inline">
                    Filtro: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="service.searchTerm"/>
                    <span class="ml-3" *ngIf="service.loading$ | async">Carregando...</span>
                </div>
                <div class="col-sm-2 col-xs-2"></div>
                <div class="col-sm-4 col-xs-4">
                <a class="nav-link" [routerLink]="['/jogador']">
                    <span tooltip="Adicionar Jogador" placement="top" show-delay="500">
                    <img src="/assets/images/icons/add.png" class="mr-2" style="width: 30px"> Jogador
                    </span> 
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-xs-12 table-responsive-xl">
                <table class="table table-striped col-sm-12 col-xs-12">
                    <thead>
                    <tr>
                        <th scope="col" sortable="JO_JONOME" (sort)="onSort($event)">Nome Jog.</th>
                        <th scope="col" sortable="JO_JOAPELIDO" (sort)="onSort($event)">Apelido</th>
                        <th scope="col" sortable="OBJ_CLUBE.CL_CLNOME" (sort)="onSort($event)">Clube</th>
                        <th scope="col">Ação</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let jogador of jogadores$ | async">
                        <td>
                            <img [src]="jogador.JO_JOFOTO" class="mr-2" style="width: 30px">
                            <span [tooltip]="jogador.JO_JONOME" placement="top" show-delay="500">
                            <ngb-highlight [result]="jogador.JO_JONOME.substring(0,15)" [term]="service.searchTerm"></ngb-highlight>
                            </span>
                        </td>
                        <td>
                            <span [tooltip]="jogador.JO_JOAPELIDO" placement="top" show-delay="500">
                            <ngb-highlight [result]="jogador.JO_JOAPELIDO.substring(0,15) " [term]="service.searchTerm"></ngb-highlight>
                            </span>
                        </td>
                        <td>
                            <span [tooltip]="jogador.OBJ_CLUBE.CL_CLNOME" placement="top" show-delay="500">
                            <img [src]="jogador.OBJ_CLUBE.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                            </span>
                        </td>
                        <td>
                            <a class="nav-link" [routerLink]="['/jogador',jogador.JO_JOID]">
                            <span tooltip="Editar" placement="top" show-delay="500">
                                <i class="fa fa-edit"></i>
                            </span> 
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>

                <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [ngValue]="30">30 itens por página</option>
                <option [ngValue]="50">50 itens por página</option>
                <option [ngValue]="100">100 itens por página</option>
                </select>
            </div>  
        </form>
    </div>
  </div>
