<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">
        &nbsp;
    </div>
</div>
<div class="row">
    <div class="col-sm-2 col-xs-2">&nbsp;</div>
    <div class="col-sm-8 col-xs-8 badge badge-primary"> <h5><img src="/assets/images/icons/ptscompeticaojogador.png" class="mr-2" style="width: 30px"> Importar Pontuação Equipes</h5></div>
</div>

<form [formGroup]="ptscompeticaojogadorForm" novalidate> 
    <div class="row">
        <div class="col-sm-2 col-xs-2">
            &nbsp;
        </div> 
        <div class="col-sm-8 col-xs-8 border border-primary rounded shadow-lg p-4 mb-4 bg-white">
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                    Ano:
                </div>
                <div class="col-sm-7  col-xs-7" >
                    <select #ano name="ano" class="form-control"  (change)="FiltraCompeticao(ano.value)"  (click)="FiltraCompeticao(ano.value)">
                        <option value="">--</option>
                        <option *ngFor=  "let c of (anos)" [value]="c.ANO">{{c.ANO}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-2 col-xs-2">
                        Competição: 
                </div>
                <div class="col-sm-7 col-xs-7" >
                    <cft-input errorMessage="Competição deve ser preenchida" label="Competição">
                        <select #PJ_CPID name="PJ_CPID" class="form-control" formControlName="PJ_CPID" (change)="MostraRodada(PJ_CPID.value)" >
                            <option value="">--</option>
                            <option *ngFor=  "let c of (competicoes)" [value]="c.CP_CPID">{{c.CP_CPDESCRICAO}} - {{c.OBJ_Rodada.RO_RODESCRICAO}} </option>
                        </select>
                    </cft-input> 
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    &nbsp;
                </div>
                <div *ngIf="show" >
                    <ngb-toast *ngIf="show" header="{{competicaoId.CP_CPDESCRICAO}}" [delay]="5000"
                        [autohide]="autohide" (mouseenter)="autohide = false" (mouseleave)="autohide = true"
                        (hide)="show = false; autohide = true" [class.bg-warning]="!autohide" >
                        <div class="row">
                            <div class="col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <img [src]="competicaoId.CP_CPFOTO" class="mr-2" style="width: 30px">
                                    <!-- </div>
                                    <div class="col-sm-11 col-xs-11"> -->
                                        {{ competicaoId.CP_CPDESCRICAO }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        {{ competicaoId.OBJ_Rodada.RO_RODESCRICAO }} Inicio:{{ competicaoId.CP_CPDATAINICIO  | date: 'dd/MM/yyyy' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngb-toast>
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-xs-1">
                    &nbsp;

                </div>
                <div class="col-sm-2 col-xs-2">
                        Jogo: 
                </div>
                <div class="col-sm-7 col-xs-7" >
                    <cft-input errorMessage="Jogo deve ser preenchido" label="Jogo">
                        <select #PJ_JGID name="PJ_JGID" class="form-control" formControlName="PJ_JGID" (change) = "EscolhaJogo(PJ_JGID.value)">
                            <option value="">--</option>
                            <option *ngFor=  "let c of (jogos)" [value]="c.JG_JGID">{{c.OBJ_CLUBE1.CL_CLSIGLA}} X {{c.OBJ_CLUBE2.CL_CLSIGLA}} </option>
                        </select>
                    </cft-input> 
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-1 col-xs-1">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    &nbsp;
                </div>
                <div *ngIf="showJogo" >
                    <ngb-toast *ngIf="showJogo" header="{{jogoId.OBJ_CLUBE1.CL_CLSIGLA}} X {{jogoId.OBJ_CLUBE2.CL_CLSIGLA}}" [delay]="10000"
                        [autohide]="autohideJogo" (mouseenter)="autohideJogo = false" (mouseleave)="autohideJogo = true"
                        (hide)="showJogo = false; autohideJogo = true" [class.bg-warning]="!autohideJogo" >
                        <div class="row">
                            <div class="col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <img [src]="jogoId.OBJ_CLUBE1.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                        {{jogoId.OBJ_CLUBE1.CL_CLSIGLA}} X
                                        <img [src]="jogoId.OBJ_CLUBE2.CL_CLEMBLEMA" class="mr-2" style="width: 30px">
                                        {{jogoId.OBJ_CLUBE2.CL_CLSIGLA}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngb-toast>
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>   
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
                <div class="col-sm-3 col-xs-3">
                    Arquivo:
                </div>
                <div class="col-sm-5  col-xs-5" >
                    <input type="file" (change)="onFileChange($event)" multiple="false" class="form-control" formControlName="PJ_PJOBSERVACAO"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    &nbsp;
                </div>
            </div>  
            <div class="row">
                <div class="col-sm-2 col-xs-2">
                    
                </div>
                
                <div class="col-sm-8 col-xs-8" >
                    <button (click)="PercorrerPlanilha(ptscompeticaojogadorForm.value)"type="button" class="btn btn-primary pull-right btn-block" [disabled]="!ptscompeticaojogadorForm.valid" >
                        <i class="fa fa-upload"></i> Importar
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>