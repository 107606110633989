<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ titulo }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ corpo }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose() ">{{ btnCancelar }}</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm() ">{{btnAcao}} </button>
    </div>
</div>